import { useState } from 'react'
import styled from '@emotion/styled'
import Konva from 'konva'
import { Stage, Layer, Rect, Text, Circle } from 'react-konva'

const BikeCanvas = styled.div`
width: 1000px;
height: 600px;
border: 2px solid white;
`

export const Bike = () => {
  const [color, setColor] = useState(Konva.Util.getRandomColor())

  return (
    <BikeCanvas>
      <Stage width={1000} height={600}>
        <Layer>
          <Text text='' />
          <Rect x={20} y={20} width={50} height={50} fill={color} shadowBlur={5} />
          <Circle x={200} y={500} radius={100} fill={color} />
          <Circle x={800} y={500} radius={100} fill={color} />
        </Layer>
      </Stage>
    </BikeCanvas>
  )
}

